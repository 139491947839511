import { h, resolveComponent } from 'vue'

const DetalleMedico = () => import('@/app/views/medicos/views/DetalleMedico');


const medicosRoutes = [
  {
    path: 'medicos',
    name: 'Medicos',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.medico'
      }
    },
    children: [
      {
        path: 'yo',
        name: 'medicos:Detalle',
        component: DetalleMedico,
        meta: {
          auth: true,
          entidad: 'medicos',
          breadcrumb: {
            title: 'GLOBAL.BREADCRUMBS.detalle'
          }
        },
      }
    ]
  }
];

export default medicosRoutes;
