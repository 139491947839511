
import { defineRule, configure, extend } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'
import es from "@vee-validate/i18n/dist/locale/es.json";
import pt_PT from "@vee-validate/i18n/dist/locale/pt_PT.json";

import { required, email, confirmed, ext, size, image, min_value, max_value } from '@vee-validate/rules'

// import moment from 'moment';

defineRule('required', required)
defineRule('validEmail', email)
defineRule('confirmed', confirmed)
defineRule('ext', ext);
defineRule('size', size);
defineRule('image', image);
defineRule('minValue', min_value);
defineRule('maxValue', max_value);
defineRule('tieneId', value => {
  return value && value.id
});
defineRule('esPositivo', value => {
  return value >= 0
});


defineRule('esPositivoOrNull', value => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  return value >= 0
});

defineRule('requiredId', value => {
  return value && value.id
});

defineRule('maxDate', (value, params) => {
  return Date.parse(value) < Date.parse(params[0])
})

defineRule('minTodayDate', (value) => {
  return Date.parse(value) > Date.parse(Date())
})

defineRule('minDate', (value, params) => {
  return Date.parse(value) > Date.parse(params[0])
})

defineRule('maxDateOrNull', (value, params) => {
  if (!value || !value.length || params[0] === 'null') {
    return true;
  }

  return Date.parse(value + ':00') < Date.parse(params[0] + ':00');

  // TODO: cambiar libreria.
  // return Date.parse(moment(value).format('YYYY-MM-DD HH:mm')) < Date.parse(moment(params[0]).format('YYYY-MM-DD HH:mm'))

})

defineRule('minDateOrNull', (value, params) => {

  if (!value || !value.length || params[0] === 'null') {
    return true;
  }

  return Date.parse(value + ':00') > Date.parse(params[0] + ':00');

  // TODO: cambiar libreria.
  // return Date.parse(moment(value).format('YYYY-MM-DD HH:mm')) < Date.parse(moment(params[0]).format('YYYY-MM-DD HH:mm'))
})

// Configuración 
const customMessages = {
  es: {
    messages: {
      required: 'El campo no puede estar vacío',
      validEmail: 'Introduce un email válido',
      confirmed: 'Las contraseñas deben coincidir',
      tieneId: 'Debes seleccionar una opción',
      requiredId: 'Debes completar el campo ',
      esPositivo: 'El número debe ser positivo',
      ext: 'El formato no es el requerido',
      size: 'El tamaño del archivo no es válido',
      image: 'El campo debe ser una imagen',
      minDate: 'La fecha debe ser mayor que la fecha de inicio',
      maxDate: 'La fecha debe ser menor que la fecha final',
      minDateOrNull: 'La fecha debe ser mayor que la fecha de inicio',
      maxDateOrNull: 'La fecha debe ser menor que la fecha final',
      minTodayDate: 'La fecha debe ser mayor que la fecha actual',
      digits: 'El campo debe ser numérico y contener exactamente 9 dígitos',
      validMovil: 'El campo debe comenzar con un 6 o 7'
    },
  },
  pt: {
    messages: {
      required: 'O campo não pode estar vazio',
      validEmail: 'Introduza um email válido',
      confirmed: 'As palavras-passe devem coincidir',
      tieneId: 'Deve selecionar uma opção',
      requiredId: 'Deve preencher o campo',
      esPositivo: 'O número deve ser positivo',
      ext: 'O formato não é o requerido',
      size: 'O tamanho do ficheiro não é válido',
      image: 'O campo deve ser uma imagem',
      minDate: 'A data deve ser maior que a data de início',
      maxDate: 'A data deve ser menor que a data final',
      minDateOrNull: 'A data deve ser maior que a data de início',
      maxDateOrNull: 'A data deve ser menor que a data final',
      minTodayDate: 'A data deve ser maior que a data atual',
      digits: 'O campo deve ser numérico e conter exatamente 9 dígitos',
      validMovil: 'O campo deve começar com um 6 ou 7'
    },
  },
}
// Fusionar los mensajes por defecto con los personalizados
const mergedLocales = {
  es: { ...es, messages: { ...es.messages, ...customMessages.es.messages } },
  pt: { ...pt_PT, messages: { ...pt_PT.messages, ...customMessages.pt.messages } },
};

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: false,
  generateMessage: localize(mergedLocales),

})

// Obtener el idioma guardado en localStorage o usar 'es' por defecto
const defaultLang = localStorage.getItem('lang') || 'es';

// Configura vee-validate con el idioma por defecto
setLocale(defaultLang);

