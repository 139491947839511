<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal"
      :size="sizeModal"
      @close="() => $emit('cancelled',false)">
      <!--   :color="colorModal" -->

      <!-- class="bg-danger text-light" -->
      <CModalHeader class="text-light" :class="`bg-${colorModal}`">
        <CModalTitle>{{$t('GLOBAL.EXPEDIENTES.MODAL.expedientes')}}</CModalTitle>
      </CModalHeader>

      <CModalBody class="text-center d-block">
        <template v-if="content">
          <h3> {{$t('GLOBAL.EXPEDIENTES.MODAL.enviar_expediente')}} </h3>
          <p>{{$t('GLOBAL.EXPEDIENTES.MODAL.deseas_enviar_resultado_a_centro_y_paciente_para_su_gestion')}}</p>
          <!-- <p>¿Desea enviar el expediente a Aesthemed para su valoración?</p> -->
          <!-- <p>El coste son <strong>9,5€</strong> y su saldo es de <strong>100€</strong>.</p> -->
        </template>
  
        <slot></slot>
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" color="link" class="text-dark">{{$t('GLOBAL.BTN.cancelar')}}</CButton>
        <CButton @click="$emit('confirm')" :color="colorModal">
          {{$t('GLOBAL.BTN.enviar')}}
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'ConfirmarModal',
  props: {
    title: { type: String, default: 'Eliminar', required: true },
    show: { type: Boolean, default: false, required: true },
    content: { type: Boolean, default: true },
    colorModal: {type: String, default: 'primary' }, // info, warning, primary
    sizeModal: {type: String, default: 'lg' }, // size="lg" 
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
